var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', {
    staticClass: "mb-2"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "MapPinIcon",
      "size": "30"
    }
  }), _vm._v(" " + _vm._s(_vm.id ? 'Editar' : 'Adicionar') + " Instituição ")], 1)])], 1)], 1)], 1)], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Dados da Instituição")]), _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-form', {
          staticClass: "chat-app-form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            },
            "reset": function reset($event) {
              $event.preventDefault();
              return _vm.resetForm.apply(null, arguments);
            }
          }
        }, [_c('b-row', {
          attrs: {
            "align-v": "start"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Nome da Empresa"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Primeiro Nome",
            "vid": "firstName",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.physicalPerson.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson, "firstName", $$v);
                  },
                  expression: "form.physicalPerson.firstName"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "País"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Digite aqui"
          },
          model: {
            value: _vm.form.nationality,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nationality", $$v);
            },
            expression: "form.nationality"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "CNPJ",
            "vid": "cnpj",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "CNPJ"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.physicalPerson.cpf,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson, "cpf", $$v);
                  },
                  expression: "form.physicalPerson.cpf"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Endereço",
            "vid": "address",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Endereço"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Rua, Avenida, número"
                },
                model: {
                  value: _vm.form.physicalPerson.person.address,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson.person, "address", $$v);
                  },
                  expression: "form.physicalPerson.person.address"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "CEP",
            "vid": "postalCode",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-group', [_c('b-form-input', {
                attrs: {
                  "placeholder": "CEP"
                },
                model: {
                  value: _vm.form.physicalPerson.person.postalCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson.person, "postalCode", $$v);
                  },
                  expression: "form.physicalPerson.person.postalCode"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Bairro",
            "vid": "neighborhood",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('b-form-group', [_c('b-form-input', {
                attrs: {
                  "placeholder": "Bairro"
                },
                model: {
                  value: _vm.form.physicalPerson.person.neighborhood,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson.person, "neighborhood", $$v);
                  },
                  expression: "form.physicalPerson.person.neighborhood"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Cidade",
            "vid": "city",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('b-form-group', [_c('b-form-input', {
                attrs: {
                  "placeholder": "Cidade"
                },
                model: {
                  value: _vm.form.physicalPerson.person.city,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson.person, "city", $$v);
                  },
                  expression: "form.physicalPerson.person.city"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Estado",
            "vid": "state",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Estado"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.physicalPerson.person.state,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.physicalPerson.person, "state", $$v);
                  },
                  expression: "form.physicalPerson.person.state"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Contato"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Fixo"
          },
          model: {
            value: _vm.form.physicalPerson.person.phoneNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.form.physicalPerson.person, "phoneNumber", $$v);
            },
            expression: "form.physicalPerson.person.phoneNumber"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Celular"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Celular"
          },
          model: {
            value: _vm.form.physicalPerson.person.mobilePhone,
            callback: function callback($$v) {
              _vm.$set(_vm.form.physicalPerson.person, "mobilePhone", $$v);
            },
            expression: "form.physicalPerson.person.mobilePhone"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Email"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "email",
            "placeholder": "exemplo@exemplo.com"
          },
          model: {
            value: _vm.form.physicalPerson.person.email,
            callback: function callback($$v) {
              _vm.$set(_vm.form.physicalPerson.person, "email", $$v);
            },
            expression: "form.physicalPerson.person.email"
          }
        })], 1)], 1)], 1)], 1)];
      }
    }])
  })], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Licença na plataforma")]), _c('b-form', {
    staticClass: "chat-app-form"
  }, [_c('b-row', {
    attrs: {
      "align-v": "start"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.visitedPsy,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "visitedPsy", $$v);
      },
      expression: "form.visitedPsy"
    }
  }, [_vm._v(" Licença ativa ")])], 1)], 1)], 1)], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Alterar Senha")]), _c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Senha Antiga",
      "label-for": "account-old-password"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "account-old-password",
      "name": "old-password",
      "type": _vm.passwordFieldTypeOld,
      "placeholder": "Senha Antiga"
    },
    model: {
      value: _vm.passwordValueOld,
      callback: function callback($$v) {
        _vm.passwordValueOld = $$v;
      },
      expression: "passwordValueOld"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": _vm.passwordToggleIconOld
    },
    on: {
      "click": _vm.togglePasswordOld
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "account-new-password",
      "label": "Nova Senha"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "account-new-password",
      "type": _vm.passwordFieldTypeNew,
      "name": "new-password",
      "placeholder": "Nova Senha"
    },
    model: {
      value: _vm.newPasswordValue,
      callback: function callback($$v) {
        _vm.newPasswordValue = $$v;
      },
      expression: "newPasswordValue"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": _vm.passwordToggleIconNew
    },
    on: {
      "click": _vm.togglePasswordNew
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "account-retype-new-password",
      "label": "Confirmar Nova Senha"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "account-retype-new-password",
      "type": _vm.passwordFieldTypeRetype,
      "name": "retype-password",
      "placeholder": "Confirmar Nova Senha"
    },
    model: {
      value: _vm.RetypePassword,
      callback: function callback($$v) {
        _vm.RetypePassword = $$v;
      },
      expression: "RetypePassword"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": _vm.passwordToggleIconRetype
    },
    on: {
      "click": _vm.togglePasswordRetype
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 mr-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Salvar Alterações ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Redefinir ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }